<template>
  <div>
    <create-or-select
      :items="extractCanvasOptions"
      :current_select="currentCanvasSelection"
      :fail-to-create-message="eventHandlerCanvasData.error"
      new-item-message="new canvas"
      :placeholder="placeHolder"
      @change="handleCanvasChange($event)"
    />
    <el-row
      type="flex"
      class="new-event-handler-msg"
      style="padding-left: 10px;"
    >
      {{ eventHandlerCanvasData.msg }}
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import { alphaNumericGrammarRegex } from "@/utils/regex";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    eventHandlerCanvas: {
      type: [Object, null]
    },
    placeHolder: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      alphaNumericGrammarRegex: alphaNumericGrammarRegex
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    ...mapGetters("canvas", {
      getNoInputNoMatchHandlerCanvasList: "getNoInputNoMatchHandlerCanvasList"
    }),

    extractCanvasOptions() {
      return _.map(this.getNoInputNoMatchHandlerCanvasList, child => ({
        label: child.canvas_name,
        value: child.canvas_id
      }));
    },
    currentCanvasSelection() {
      const { canvas_id, canvas_name } = this.eventHandlerCanvasData;
      return canvas_id === -1 ? canvas_name : canvas_id;
    },
    eventHandlerCanvasData() {
      if (
        _.isNull(this.eventHandlerCanvas) ||
        _.isUndefined(this.eventHandlerCanvas)
      ) {
        return {
          canvas_name: "",
          canvas_id: -1,
          msg: ""
        };
      }
      return this.eventHandlerCanvas;
    }
  },
  methods: {
    handleCanvasChange(option) {
      this.$emit("change", {
        canvas_id: option.value,
        canvas_name: option.label,
        msg: option.msg
      });
    }
  },
  watch: {}
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
.new-event-handler-msg {
  font-size: 0.75rem;
  color: $--color-success;
}
</style>
