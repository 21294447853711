import { render, staticRenderFns } from "./CreateOrSelectCanvas.vue?vue&type=template&id=419e58dc&scoped=true"
import script from "./CreateOrSelectCanvas.vue?vue&type=script&lang=js"
export * from "./CreateOrSelectCanvas.vue?vue&type=script&lang=js"
import style0 from "./CreateOrSelectCanvas.vue?vue&type=style&index=0&id=419e58dc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419e58dc",
  null
  
)

export default component.exports